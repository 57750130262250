import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  FormControl,
  Button,
  VStack,
  HStack,
  Text,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import rdvService from "services/rdvService";
import "trix/dist/trix.css";
import "trix";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaCheck, FaTimes } from "react-icons/fa";

const RdvCommentsTab = ({ data, accessToken, onStateChange }) => {
  const { t } = useTranslation();
  const [newComment, setNewComment] = useState("");
  const editorRef = useRef(null);

  useEffect(() => {
    console.log("original_rdv", data.original_rdv.id)
    const editor = editorRef.current;

    const handleEditorChange = (event) => {
      const htmlContent = event.target.innerHTML;
      setNewComment(htmlContent);
    };

    if (editor) {
      editor.addEventListener("trix-change", handleEditorChange);

      // Clean up event listener on component unmount
      return () => {
        editor.removeEventListener("trix-change", handleEditorChange);
      };
    }
  }, []);

  const handleAddComment = async () => {
    if (!newComment.trim()) {
      return; // Don't proceed if the comment is empty
    }

    try {
      await rdvService.addComment({
        pk: data.original_rdv.id,
        obj: {
          comment: newComment,
        },
        accessToken,
      });

      toast.success(`${t('messages.changesSaved')}`, {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      onStateChange();

      setNewComment("");
      editorRef.current.editor.loadHTML("");
    } catch (error) {
      toast.error(`${t('messages.failedToAddComment')}`, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
  console.log("data in comments tab", data)

  return (
    <Box width={"50%"} height={"auto"}>
      <FormControl mt={4} width={"100%"}>
        <div>
          <input id="trix-input" type="hidden" name="content" />
          <trix-editor input="trix-input" ref={editorRef}></trix-editor>
        </div>
        <Box textAlign="right" mt={4}>
          <Button
            isDisabled={!newComment.trim() || data.original_rdv.is_closed}
            bg="#FF6666"
            color="white"
            _hover={{ bg: "#FF5050" }}
            _active={{ bg: "#E64E4E" }}
            _focus={{ boxShadow: "0 0 1px 2px #FF9999" }}
            paddingX={6}
            paddingY={4}
            fontSize="md"
            borderRadius="md"
            transition="all 0.2s"
            onClick={handleAddComment}
          >
            {t("admin__appointments__save_comment")}
          </Button>
        </Box>
      </FormControl>

      {Array.isArray(data.thread) && data.thread.length > 0 && (
        <VStack mt={8} spacing={4} align="stretch">
          {data.original_rdv.thread.map((threadItem, index) => (
            <Box key={index} p={4} borderWidth="1px" borderRadius="md" boxShadow="md">
              <HStack justify="space-between">
                <Text fontWeight="bold">{threadItem.commented_by.name}</Text>
                <Text color="gray.500" fontSize="sm">
                  {new Date(threadItem.commented_on).toLocaleString('fr', {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                  })}
                </Text>
              </HStack>
              <Box mt={2} dangerouslySetInnerHTML={{ __html: threadItem.comment }} />
            </Box>
          ))}
        </VStack>
      )}
    </Box>
  );
};

export default RdvCommentsTab;

import axiosInstance from '../axiosConfig';
import axios from 'axios';

const API_URL = `${process.env.REACT_APP_API_BASE_URL}collaboration-api/service-provider/rdv`;
const API_URL_two = `${process.env.REACT_APP_API_BASE_URL}collaboration-api/service-provider`;
const API_URL_gen = `${process.env.REACT_APP_API_BASE_URL}collaboration-api`;

const rdvService = {
  getAll: async (accessToken, limit = 10, offset = 0) => {
    try {
      const response = await axiosInstance.get(`${API_URL}/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        params: {
          limit,
          offset,
        },
      });
      console.log({ getAll: response.data });
      return {
        data: response.data,
      };
    } catch (error) {
      throw new Error("Request failed. Please check your credentials.");
    }
  },
  get: async ({ pk, accessToken }) => {
    try {
      const response = await axiosInstance.get(`${API_URL}/${pk}/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return {
        data: response.data,
      };
    } catch (error) {
      throw new Error("Request failed. Please check your credentials.");
    }
  },

  new: async (accessToken, formData) => {
    try {
      const response = await axiosInstance.post(`${API_URL}/create/`, formData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      });
      return {
        data: response.data,
      };
    } catch (error) {
      throw error;

    }
  },

  update: async ({ pk, obj, accessToken }) => {
    try {
      const response = await axiosInstance.patch(`${API_URL}/${pk}/`, obj, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return {
        data: response.data,
      };
    } catch (error) {
      throw new Error("Request failed. Please check your credentials.");
    }
  },
  completReq: async ({ Id, obj, accessToken }) => {
    try {
      const response = await axiosInstance.post(`${API_URL}/${Id}/complete/`, obj, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      // Check if response status is not 200
      if (response.status !== 200) {
        const errorMessage = response.data?.message || "Request failed. Please check your credentials.";
        return {
          error: errorMessage,
        };
      }

      return {
        data: response.data,
      };
    } catch (error) {
      // Handle unexpected errors
      const errorMessage = error.response?.data?.message || "Request failed. Please check your credentials.";
      return {
        error: errorMessage,
      };
    }
  },


  addComment: async ({ pk, obj, accessToken }) => {
    try {
      // console.log('calling adding a new comment');
      const response = await axiosInstance.post(`${API_URL}/${pk}/comment/`, obj, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });


      return {
        data: response.data,
      };
    } catch (error) {
      throw new Error("Request failed. Please check your credentials.");
    }
  },
  updateDoc: async ({ pk, payload, docId }) => {
    try {
      // console.log(`Payload is ${JSON.stringify(payload)}`);
      const response = await axiosInstance.patch(`${API_URL}${pk}/document/${docId}/`, payload);
      return {
        data: response.data,
      };
    } catch (error) {
      throw new Error("Update failed, please log out and try again.");
    }
  },
  updateParticipantInfo: async ({ rdvId, participantId, obj, accessToken }) => {
    try {
      const response = await axiosInstance.patch(`${API_URL}/${rdvId}/participant/${participantId}`, obj, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return {
        data: response.data,
      };
    } catch (error) {
      throw new Error("Request failed. Please check your credentials.");
    }
  },
  updateChildInfo: async ({ rdvId, childId, obj, accessToken }) => {
    try {
      const response = await axiosInstance.patch(`${API_URL}/${rdvId}/child/${childId}`, obj, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return {
        data: response.data,
      };
    } catch (error) {
      throw new Error("Request failed. Please check your credentials.");
    }
  },
  deleteParticipant: async ({ pk, participantId, accessToken }) => {
    console.log("Deleting Participant:", { pk, participantId });

    try {
      const response = await axiosInstance.delete(
        `${API_URL}/${pk}/participant/${participantId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      // Check for successful response
      if (response.status !== 204) {
        throw new Error(`Failed to delete participant: ${response.data}`);
      }

      return {
        data: response.data, // Return relevant data if needed
      };
    } catch (error) {
      console.error("Error deleting participant:", error);
      throw new Error(`Error deleting participant: ${error.message}`); // Throw a more informative error
    }
  },

  deleteDoc: async (rdvId, docId) => {
    try {

      const res = await axiosInstance.delete(`${API_URL}/${rdvId}/document/${docId}/`);
      return {
        data: res.data
      }
    }
    catch (error) {
      throw new Error('Failed to delete the session doc');
    }
  },
  updateDoc: async ({ pk, payload, docId }) => {
    try {

      const response = await axiosInstance.patch(`${API_URL}/${pk}/document/${docId}/`, payload);

      return {
        data: response.data,
      };
    } catch (error) {
      throw new Error("Update failed, please log out and try again.");
    }
  },
  addParticipant: async ({ pk, participant, accessToken }) => {
    try {
      const response = await axiosInstance.post(
        `${API_URL}/${pk}/participant`,
        participant,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return {
        data: response.data,
      };
    } catch (error) {
      throw new Error("Request failed. Please check your credentials.");
    }
  },
  addChild: async ({ pk, child, accessToken }) => {
    try {
      const response = await axiosInstance.post(`${API_URL}/${pk}/child/`, child, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return {
        data: response.data,
      };
    } catch (error) {
      throw new Error("Request failed. Please check your credentials.");
    }
  },
  softDelete: ({ rdv_id }) => {
    throw new Error("Not implemented");
  },
  search: async (accessToken, query, limit = 10, offset = 0) => {
    try {
      // console.log('search query is');
      // console.log(query);
      // console.log(`${API_URL}/${query}`);
      const response = await axiosInstance.get(`${API_URL}/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        params: { ...query, limit, offset },
      });
      return {
        data: response.data,
      };
    } catch (error) {
      throw new Error("Search failed. Please check your credentials.");
    }
  },
  filter: async (accessToken, filters, limit = 10, offset = 0) => {
    // console.log('search query is');
    // console.log(filters);

    try {
      const response = await axiosInstance.get(`${API_URL}/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
        params: filters
      });
      return {
        data: response.data,
      };
    } catch (error) {
      throw new Error("filter failed. Please check your credentials.");
    }
  },

  calendarBlock: async (accessToken, locationId, service) => {
    // console.log("from api location id is:", locationId, service);
    try {
      const response = await axios.get(`${API_URL_two}/session`, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
        params: {
          location: Array.isArray(locationId) ? locationId.join(',') : locationId, // Convert array to comma-separated string
          type: service
        }
      });
      return {
        data: response.data,
      };
    } catch (error) {
      throw new Error("Calendar failed. Please check your credentials.");
    }
  },

  calendarBlockWithId: async (accessToken, id) => {
    // console.log("from api location id is:", "id:", id);
    try {
      const response = await axios.get(`${API_URL_two}/session/${id}`, {  // Add `id` to the URL
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });
      return {
        data: response.data,
      };
    } catch (error) {
      throw new Error("Calendar with ID failed. Please check your credentials.");
    }
  },

  serviceListCall: async (accessToken,
    locationId,
    service,
    offset,
    limit,
    participant_name,
    participant_email,
    file_number,
    status,
    from,
    to
  ) => {
    // console.log("from api location id is:", locationId, service);
    try {
      const response = await axios.get(`${API_URL_two}/session`, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
        params: {
          location: Array.isArray(locationId) ? locationId.join(',') : locationId, // Convert array to comma-separated string
          type: service,
          offset,
          limit,
          participant_name,
          participant_email,
          file_number,
          status,
          from,
          to
        }
      });
      return {
        data: response.data,
      };
    } catch (error) {
      // throw new Error("Calendar failed. Please check your credentials.");
      return { error: error.response };
    }
  },

  sessionDetailsCall: async ({ accessToken, id }) => {

    try {
      const response = await axios.get(`${API_URL_two}/session/${id}/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });
      return {
        data: response.data,
      }
    } catch (error) {
      throw error;
    }
  },

  availability: async (accessToken) => {

    try {
      const response = await axios.get(`${API_URL_two}/availability`, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });
      return {
        data: response.data,
      }
    } catch (error) {
      throw new Error("Availability failed. Please check your credentials.");
    }
  },

  profileAvailability: async (accessToken, params) => {
    try {
      const response = await axios.get(`${API_URL_two}/availability`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        params: {
          location: params.locations,

        },
      });
      return {
        data: response.data,
      };
    } catch (error) {
      throw error
    }
  },

  statistics: async (accessToken) => {
    try {
      const response = await axios.get(`${API_URL_two}/statistics/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });
      return {
        data: response.data,
      }
    } catch (error) {
      // Check if the error response status is 401 (Unauthorized)
      if (error.response && error.response.status === 401) {
        // store.dispatch(logout()); // Dispatch the logout action
        console.log("the response 401")
      }
      return { error: error.response || error.message };
    }
  },

  profileUpdate: async (accessToken, formData) => {
    try {
      const response = await axios.patch(`${API_URL_two}/profile/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${accessToken}`
        }
      });
      return {
        data: response.data,
      };
    } catch (error) {
      throw new Error("Update failed, please log out and try again.");
    }
  },

  getServices: async (accessToken, slug) => {
    try {
      // Base URL
      let url = `${API_URL_gen}/service/?limit=100&offset=0`;

      // Append slug if it is available
      if (slug) {
        url = `${API_URL_gen}/${slug}/service/?limit=100&offset=0`;
      }

      // Make the API call
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });

      // Return the data received from the API
      return {
        data: response.data,
      };
    } catch (error) {
      // Handle error appropriately
      return { error: error.response || error.message };
    }
  },

  getConsultationMode: async (accessToken, slug) => {
    try {
      // Base URL
      let url = `${API_URL_gen}/cithea/consultation-mode/`;

      // Append slug if it is available
      if (slug) {
        url = `${API_URL_gen}/${slug}/consultation-mode/`;
      }

      // Make the API call
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });

      // Return the data received from the API
      return {
        data: response.data,
      };
    } catch (error) {
      // Handle error appropriately
      return { error: error.response || error.message };
    }
  },

  availabilityBulkCreate: async (accessToken, objectBlocks) => {
    try {
      const response = await axios.post(`${API_URL_two}/availability-seed/bulk/create/`, objectBlocks, {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      }
      );
      return {
        data: response.data,
      };
    } catch (error) {
      throw error;
    }
  }

};




export default rdvService;

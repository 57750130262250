import React from "react";
import { Icon, layout } from "@chakra-ui/react";
import {
  MdBarChart,
  MdPerson,
  MdHome,
  MdLock,
  MdOutlineShoppingCart,
  MdVerifiedUser,
} from "react-icons/md";
// Admin Imports
import MainDashboard from "views/admin/default";
import ServiceProviders from "views/admin/serviceProviders";
import Location from "views/admin/Locations"
import NFTMarketplace from "views/admin/marketplace";
import Profile from "views/admin/profile";
import DataTables from "views/admin/dataTables";
import MediationManagement from "views/admin/appointmentCheckin";
import Calendar from 'views/admin/calendar'
import Settings from "views/admin/settings"
// Auth Imports
import SignInCentered from "views/auth/signIn";
import { ServiceProvider } from "views/admin/marketplace/variables/TableServiceProviders.model";
import ResetPassword from "views/auth/reset-password";
import ResetPasswordConfirm from "views/auth/password-reset-confirm";


const routes = [
  {
    name: "Main Dashboard",
    layout: "/admin",
    path: "/default",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: MainDashboard,
    isPublic: false,
  },
  {
    name: "Calendar",
    layout: "/admin",
    path: "/calendar",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: Calendar,
    isPublic: false,
  },
  {
    name: "Associates",
    layout: "/admin",
    path: "/view-associates",
    icon: (
      <Icon as={MdVerifiedUser} width="20px" height="20px" color="inherit" />
    ),
    component: NFTMarketplace,
    secondary: true,
    isPublic: false,
  },
  {
    name: "RDV Appointments",
    layout: "/admin",
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: "/data-tables",
    component: DataTables,
    isPublic: false,
  },
  {
    name: "RDV Info Center",
    layout: "/admin",
    path: "/profile",
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: Profile,
    isPublic: false,
  },
  {
    name: "RDV Mediation Center",
    layout: "/auth",
    path: "/sign-in",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: SignInCentered,
    visible: false,
    isPublic: true,
  },
  {
    name: "Appointment Management",
    layout: "/admin",
    path: "/appointment-checkin/:appointmentId",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: MediationManagement,
    visible: false,
    isPublic: false,
  },
  {
    name: "Sign In",
    layout: "/auth",
    path: "/sign-in",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: SignInCentered,
    isPublic: true,
  },
  {
    name: "RDV Mediation Center",
    layout: "/auth",
    path: "/reset-password",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: ResetPassword,
    visible: false,
    isPublic: true,
  },
  {
    name: "RDV Mediation Center",
    layout: "/auth",
    path: "/password-reset-confirm",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: ResetPasswordConfirm,
    visible: false,
    isPublic: true,
  },
  {
    name: "Service Providers",
    layout: "/admin",
    path: "/service-providers",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: ServiceProviders,
    isPublic: false,
  },
    {
    name: "Locations",
    layout: "/admin",
    path: "/locations",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: Location,
    isPublic: false,
  },
  {
    name:"Settings",
    layout:"/admin",
    path:"/settings/*",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component:Settings,
    isPublic:false
  }
];

export default routes;
// {
//   name: "RTL Admin",
//   layout: "/rtl",
//   path: "/rtl-default",
//   icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
//   component: RTL,
// },;

import React, { useState, useEffect, useCallback } from "react";
import {
  Text,
  Flex,
  Box,
  IconButton,
  Image,
  Link,
  useColorModeValue,
  Avatar,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  textDecoration
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { logout } from "actions/authActions";
import LanguageSelector from "./LanguageSelector";
import { connect } from "react-redux";
import { selectUser, selectAssociation, selectProfessionalProfile } from "selectors/authSelector";
import Utils from "services/Utils";
const environment = process.env.REACT_APP_DEV; // Declared environment variable

const CBrandHeader = ({ association, user, professionalProfile, environment }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [activeTab, setActiveTab] = useState();
  const [fullname, setFullName] = useState(professionalProfile?.first_name + " " + professionalProfile?.last_name)
  const timeZoneInfo = Utils.getLocalTimeZoneInfo(); // Call the function once

  const GetTimeZone = `${timeZoneInfo.timeZone} - ${timeZoneInfo.abbreviation}`; // Create the string


  const handleTabChange = useCallback((tab) => {
    setActiveTab(tab);
    history.push(`/admin/${tab}`);
  }, [history]);

  useEffect(() => {
    const path = history.location.pathname.split("/").pop();
    const validTab = ["default", "calendar"].includes(path) ? path : "";
    setActiveTab(validTab);
  }, [history.location.pathname]);


  // useEffect(() => {
  //   console.log("fullname", fullname)
  // }, [])

  // const activeColor = useColorModeValue("#e64e64", "#e64e64");
  // const inactiveColor = useColorModeValue("gray.500", "gray.300");

  const activeColor = {
    textDecoration: "none",
    color: "#FF6666",
    cursor: "pointer",
  };

  const inactiveColor = {
    textDecoration: "none",
    color: "#252525",
    cursor: "pointer",
  };

  return (
    <Box borderColor="gray.300" paddingY="10px" marginBottom="8px">
      <Flex direction="column">
        <Flex justify="space-between" mx={8} py={1}>
          <Box display={"flex"} flexDirection={"row"} alignItems={"end"}>
            <Image
              src="https://kiido-media.s3.eu-west-3.amazonaws.com/Kiido_Logo_Original+(1).png"
              alt="Kiido Logo"
              height="50px"
              width="auto"
            />
            <Text fontSize={14} fontWeight={500} color={"#FF6666"}>Backstage</Text>
          </Box>
          <Flex alignItems="center" fontSize="18px" fontWeight="bold" gap="24px">
            <Link
              // href="/admin/default"
              style={activeTab === "default" ? activeColor : inactiveColor}
              onClick={() => handleTabChange("default")}
            >
              {t("admin__appointments__sessions")}
            </Link>
            <Link
              // href="/admin/calendar"
              style={activeTab === "calendar" ? activeColor : inactiveColor}
              onClick={() => handleTabChange("calendar")}
            >
              {t("admin_nav_calendar")}
            </Link>
          </Flex>
          <Flex gap={16}>
            <Flex height="fit-content" my="auto">
              <LanguageSelector />
            </Flex>
            <Box my="auto">
              <Menu>
                <MenuButton p="0px">
                  <Avatar
                    _hover={{ cursor: "pointer" }}
                    color="white"
                    name={fullname ?? "User"}
                    bg="#11047A"
                    size="sm"
                    w="40px"
                    h="40px"
                  />
                </MenuButton>
                <MenuList
                  boxShadow="lg"
                  p="20px"
                  px="60px"
                  mt="10px"
                  borderRadius="8px"
                  bg="white"
                  border="none"
                >
                  <Flex w="100%" mb="0px" justifyContent="center" paddingTop="5px">
                    <Avatar
                      _hover={{ cursor: "pointer" }}
                      color="white"
                      name={professionalProfile?.first_name + " " + professionalProfile?.last_name ?? "User"}
                      bg="#11047A"
                      size="sm"
                      w="60px"
                      h="60px"
                      bgImage={professionalProfile?.display_picture}
                    />
                  </Flex>
                  <Flex w="100%" mb="0px" justify="center">
                    <Text
                      ps="20px"
                      pt="16px"
                      w="100%"
                      borderColor="gray.300"
                      fontSize="16px"
                      fontWeight="600"
                      color="secondaryGray.900"
                      justifyContent="center"
                      textAlign="center"
                    >
                      {professionalProfile.first_name + " " + professionalProfile.last_name ?? "User"}
                      <Text color="#5F6368" fontWeight="400" fontSize="14px">
                        {t("mediator")}
                      </Text>
                    </Text>
                  </Flex>
                  <Flex flexDirection="column" p="1px" gap="0px">
                    <MenuItem
                      _hover={{ bg: "none" }}
                      _focus={{ bg: "none" }}
                      borderRadius="8px"
                      px="14px"
                      py="0px"
                    >
                      <Text fontSize="sm" color="#FF6666" textAlign="center" width="full">
                        {user?.email}
                      </Text>
                    </MenuItem>
                    <MenuItem
                      _hover={{ bg: "none" }}
                      _focus={{ bg: "none" }}
                      borderRadius="8px"
                      px="14px"
                      py="0px"
                    >
                      <Text fontSize="sm" width="full" textAlign="center" color="gray.600">
                        {GetTimeZone}
                      </Text>
                    </MenuItem>
                    <MenuItem
                      _hover={{ bg: "none" }}
                      _focus={{ bg: "none" }}
                      borderRadius="8px"
                      px="14px"
                      py="10px"
                    >
                      <Text fontSize="sm" color="gray.600" width="full" textAlign="center">
                        {t("admin__home__subscription__prompt")} -{" "}
                        <strong style={{ textTransform: 'capitalize' }}>
                          {/* {t("admin__home__subscription__value")} */}
                          {environment}
                        </strong>
                      </Text>
                    </MenuItem>
                    <Flex direction="row" justify="space-around" gap="20px">
                      <MenuItem
                        width="fit-content"
                        border="1px"
                        borderRadius="4px"
                        borderColor="gray.300"
                        color="red.400"
                        px="20px"
                        onClick={() => history.push('/admin/settings/profile')}
                      >
                        <Text fontSize="14px" fontWeight="600">
                          {t("admin__profile__settings")}
                        </Text>
                      </MenuItem>
                      <MenuItem
                        _hover={{ bg: "none" }}
                        _focus={{ bg: "none" }}
                        color="red.400"
                        px="20px"
                        borderRadius="4px"
                        border="1px"
                        borderColor="gray.300"
                        width="fit-content"
                        onClick={() => {
                          dispatch(logout());
                          history.push("/auth/sign-in");
                        }}
                      >
                        <Text fontSize="14px" fontWeight="600">
                          {t("admin__home__sign_out")}
                        </Text>
                      </MenuItem>
                    </Flex>
                  </Flex>
                </MenuList>
              </Menu>
            </Box>
          </Flex>
        </Flex>
        <Flex px={8} py={3} borderY={"solid 1px #E7E7E7"} alignItems={"center"}>
          <Image
            src={association?.logo}
            alt=""
            sx={{
              height: "50px",
              width: "auto",
            }}
          />
        </Flex>
      </Flex>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  association: selectAssociation(state),
  user: selectUser(state),
  professionalProfile: selectProfessionalProfile(state),
  environment
});

export default connect(mapStateToProps)(CBrandHeader);

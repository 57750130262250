import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { ScheduleComponent, ViewsDirective, ViewDirective, Inject, Day, Week, Month, Agenda } from '@syncfusion/ej2-react-schedule';
import { registerLicense } from '@syncfusion/ej2-base';
import { loadCldr, L10n } from '@syncfusion/ej2-base';
import { useTranslation } from "react-i18next";
import {
    Box,
    Input,
    InputGroup,
    InputLeftElement,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Checkbox,
    VStack,
    Flex,
    HStack,
    Tag,
    TagLabel,
    Spinner,
    Button,
    IconButton,
    Text
} from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import { CloseIcon, RepeatIcon } from '@chakra-ui/icons';
import MonthCalendar from './MonthCalendar';
import rdvService from 'services/rdvService';
import { connect } from 'react-redux';
import { selectAccessToken, selectLocations, selectProfessionalProfile, selectAssociation } from 'selectors/authSelector';
import { useSelector } from "react-redux";
import * as numberingSystems from 'cldr-data/supplemental/numberingSystems.json';
import * as gregorian from 'cldr-data/main/fr/ca-gregorian.json';
import * as numbers from 'cldr-data/main/fr/numbers.json';
import * as timeZoneNames from 'cldr-data/main/fr/timeZoneNames.json';
import { setAction, ActionTypes } from "../../../../reducers/calendarReducer"
import { useDispatch } from 'react-redux';
import calendarService from 'services/calendarService';
import Utils from 'services/Utils';


registerLicense(
    "Ngo9BigBOggjHTQxAR8/V1NCaF5cXmZCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWXZfcnRURWldVUB1XUE="
);


loadCldr(numberingSystems, gregorian, numbers, timeZoneNames);


L10n.load({
    "en": {
        "schedule": {
            "day": "Day",
            "week": "Week",
            "workWeek": "Work Week",
            "month": "Month",
            "year": "Year",
            "agenda": "Agenda",
            "weekAgenda": "Week Agenda",
            "workWeekAgenda": "Work Week Agenda",
            "monthAgenda": "Month Agenda",
            "today": "Today",
            "noEvents": "No events",
            "emptyContainer": "There are no events scheduled on this day.",
            "allDay": "All day",
            "start": "Start",
            "end": "End",
            "more": "more",
            "close": "Close",
            "cancel": "Cancel",
            "noTitle": "(No Title)",
            "delete": "Delete",
            "deleteEvent": "Delete Event",
            "deleteMultipleEvent": "Delete Multiple Events",
            "selectedItems": "Items selected",
            "deleteSeries": "Delete Series",
            "edit": "Edit",
            "editSeries": "Edit Series",
            "editEvent": "Edit Event",
            "createEvent": "Create",
            "subject": "Subject",
            "addTitle": "Add title",
            "moreDetails": "More Details",
            "moreEvents": "More Events",
            "save": "Save",
            "editContent": "Do you want to edit only this event or entire series?",
            "deleteRecurrenceContent": "Do you want to delete only this event or entire series?",
            "deleteContent": "Are you sure you want to delete this event?",
            "deleteMultipleContent": "Are you sure you want to delete the selected events?",
            "newEvent": "New Event",
            "title": "Title",
            "location": "Location",
            "description": "Description",
            "timezone": "Timezone",
            "startTimezone": "Start Timezone",
            "endTimezone": "End Timezone",
            "repeat": "Repeat",
            "saveButton": "Save",
            "cancelButton": "Cancel",
            "deleteButton": "Delete",
            "recurrence": "Recurrence",
            "wrongPattern": "The recurrence pattern is not valid.",
            "seriesChangeAlert": "The changes made to specific instances of this series will be cancelled and those events will match the series again.",
            "createError": "The duration of the event must be shorter than how frequently it occurs. Shorten the duration, or change the recurrence pattern in the recurrence event editor.",
            "recurrenceDateValidation": "Some months have fewer than the selected date. For these months, the occurrence will fall on the last date of the month.",
            "sameDayAlert": "Two occurrences of the same event cannot occur on the same day.",
            "occurenceAlert": "Cannot reschedule an occurrence of the recurring appointment if it skips over a later occurrence of the same appointment.",
            "editRecurrence": "Edit Recurrence",
            "repeats": "Repeats",
            "alert": "Alert",
            "startEndError": "The selected end date occurs before the start date.",
            "invalidDateError": "The entered date value is invalid.",
            "blockAlert": "Events cannot be scheduled within the blocked time range.",
            "ok": "Ok",
            "yes": "Yes",
            "no": "No",
            "occurrence": "Occurrence",
            "series": "Series",
            "previous": "Previous",
            "next": "Next",
            "timelineDay": "Timeline Day",
            "timelineWeek": "Timeline Week",
            "timelineWorkWeek": "Timeline Work Week",
            "timelineMonth": "Timeline Month",
            "expandAllDaySection": "Expand",
            "collapseAllDaySection": "Collapse",
            "timelineYear": "Timeline Year",
            "editFollowingEvent": "Following Events",
            "deleteTitle": "Delete Event",
            "editTitle": "Edit Event",
            "beginFrom": "Begin From",
            "endAt": "End At",
            "searchTimezone": "Search Timezone",
            "noRecords": "No records found",
        },
        "recurrenceeditor": {
            "none": "None",
            "daily": "Daily",
            "weekly": "Weekly",
            "monthly": "Monthly",
            "month": "Month",
            "yearly": "Yearly",
            "never": "Never",
            "until": "Until",
            "count": "Count",
            "first": "First",
            "second": "Second",
            "third": "Third",
            "fourth": "Fourth",
            "last": "Last",
            "repeat": "Repeat",
            "repeatEvery": "Repeat Every",
            "on": "Repeat On",
            "end": "End",
            "onDay": "Day",
            "days": "Day(s)",
            "weeks": "Week(s)",
            "months": "Month(s)",
            "years": "Year(s)",
            "every": "every",
            "summaryTimes": "time(s)",
            "summaryOn": "on",
            "summaryUntil": "until",
            "summaryRepeat": "Repeats",
            "summaryDay": "day(s)",
            "summaryWeek": "week(s)",
            "summaryMonth": "month(s)",
            "summaryYear": "year(s)"
        }
    },
    "fr": {
        "schedule": {
            "day": "Jour",
            "week": "Semaine",
            "workWeek": "Semaine de travail",
            "month": "Mois",
            "year": "Année",
            "agenda": "Agenda",
            "weekAgenda": "Agenda de la semaine",
            "workWeekAgenda": "Agenda de la semaine de travail",
            "monthAgenda": "Agenda du mois",
            "today": "Aujourd'hui",
            "noEvents": "Aucun événement",
            "emptyContainer": "Il n'y a aucun événement prévu ce jour-là.",
            "allDay": "Toute la journée",
            "start": "Début",
            "end": "Fin",
            "more": "plus",
            "close": "Fermer",
            "cancel": "Annuler",
            "noTitle": "(Sans titre)",
            "delete": "Supprimer",
            "deleteEvent": "Supprimer l'événement",
            "deleteMultipleEvent": "Supprimer plusieurs événements",
            "selectedItems": "Éléments sélectionnés",
            "deleteSeries": "Supprimer la série",
            "edit": "Modifier",
            "editSeries": "Modifier la série",
            "editEvent": "Modifier l'événement",
            "createEvent": "Créer",
            "subject": "Sujet",
            "addTitle": "Ajouter un titre",
            "moreDetails": "Plus de détails",
            "moreEvents": "Plus d'événements",
            "save": "Enregistrer",
            "editContent": "Voulez-vous modifier uniquement cet événement ou toute la série?",
            "deleteRecurrenceContent": "Voulez-vous supprimer uniquement cet événement ou toute la série?",
            "deleteContent": "Êtes-vous sûr de vouloir supprimer cet événement?",
            "deleteMultipleContent": "Êtes-vous sûr de vouloir supprimer les événements sélectionnés?",
            "newEvent": "Nouvel événement",
            "title": "Titre",
            "location": "Emplacement",
            "description": "Description",
            "timezone": "Fuseau horaire",
            "startTimezone": "Fuseau horaire de début",
            "endTimezone": "Fuseau horaire de fin",
            "repeat": "Répéter",
            "saveButton": "Enregistrer",
            "cancelButton": "Annuler",
            "deleteButton": "Supprimer",
            "recurrence": "Récurrence",
            "wrongPattern": "Le modèle de récurrence n'est pas valide.",
            "seriesChangeAlert": "Les modifications apportées à des instances spécifiques de cette série seront annulées et ces événements correspondront à nouveau à la série.",
            "createError": "La durée de l'événement doit être inférieure à la fréquence de récurrence. Réduisez la durée ou modifiez le modèle de récurrence dans l'éditeur d'événements récurrents.",
            "recurrenceDateValidation": "Certains mois ont moins de jours que la date sélectionnée. Pour ces mois, l'occurrence aura lieu le dernier jour du mois.",
            "sameDayAlert": "Deux occurrences du même événement ne peuvent pas avoir lieu le même jour.",
            "occurenceAlert": "Impossible de reprogrammer une occurrence de l'événement récurrent si elle passe à côté d'une occurrence ultérieure du même événement.",
            "editRecurrence": "Modifier la récurrence",
            "repeats": "Répétitions",
            "alert": "Alerte",
            "startEndError": "La date de fin sélectionnée se produit avant la date de début.",
            "invalidDateError": "La valeur de date entrée est invalide.",
            "blockAlert": "Les événements ne peuvent pas être programmés pendant la période bloquée.",
            "ok": "D'accord",
            "yes": "Oui",
            "no": "Non",
            "occurrence": "Occurrence",
            "series": "Série",
            "previous": "Précédent",
            "next": "Suivant",
            "timelineDay": "Jour de la chronologie",
            "timelineWeek": "Semaine de la chronologie",
            "timelineWorkWeek": "Semaine de travail de la chronologie",
            "timelineMonth": "Mois de la chronologie",
            "expandAllDaySection": "Développer",
            "collapseAllDaySection": "Réduire",
            "timelineYear": "Année de la chronologie",
            "editFollowingEvent": "Événements suivants",
            "deleteTitle": "Supprimer l'événement",
            "editTitle": "Modifier l'événement",
            "beginFrom": "Commence à partir de",
            "endAt": "Fin à",
            "searchTimezone": "Rechercher un fuseau horaire",
            "noRecords": "Aucun enregistrement trouvé",
        },
        "recurrenceeditor": {
            "none": "Aucune",
            "daily": "Quotidien",
            "weekly": "Hebdomadaire",
            "monthly": "Mensuel",
            "month": "Mois",
            "yearly": "Annuel",
            "never": "Jamais",
            "until": "Jusqu'à",
            "count": "Nombre",
            "first": "Premier",
            "second": "Deuxième",
            "third": "Troisième",
            "fourth": "Quatrième",
            "last": "Dernier",
            "repeat": "Répéter",
            "repeatEvery": "Répéter chaque",
            "on": "Répéter sur",
            "end": "Fin",
            "onDay": "Jour",
            "days": "Jour(s)",
            "weeks": "Semaine(s)",
            "months": "Mois",
            "years": "Année(s)",
            "every": "chaque",
            "summaryTimes": "fois",
            "summaryOn": "sur",
            "summaryUntil": "jusqu'à",
            "summaryRepeat": "Répétitions",
            "summaryDay": "jour(s)",
            "summaryWeek": "semaine(s)",
            "summaryMonth": "mois",
            "summaryYear": "année(s)"
        }
    }
});


const CalendarMain = ({ accessToken, professional_profile, association }) => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const [selectedDate, setSelectedDate] = useState(new Date()); // October 1, 2024

    // const [calendarBlock, setCalendarBlock] = useState([]);
    const [selectedLocations, setSelectedLocations] = useState([]); // Multiple locations state
    const [selectedService, setSelectedService] = useState(null); // Single service state
    const locationsList = useSelector(selectLocations);
    const [availabilityBlock, setAvailabilityBlock] = useState([])

    const currentLocale = i18n.language === 'fr' ? 'fr' : 'en';

    const {

        accordionIndex,
        sessionTypeFilter,
        mediationStatusFilter,
        currentView,


        selectedMediator,
        selectedMeds,

        mediatorsList,
        filteredMedsList,
        mediatorsListIsLoading,
        sessionsList,
        timeSlotsList,
        isLoadingSessions,
        isLoadingTimeSlots,
        error,
        isMediatorFirst
    } = useSelector(state => state.calendar);

    useEffect(() => {
        if (locationsList.length === 0) {
            dispatch(setAction(ActionTypes.SET_LOCATIONS_LIST, professional_profile.locations));
        }
    }, [professional_profile.locations, dispatch]);
    const getDayAbbreviation = (dayIndex) => {
        const days = ['MO', 'TU', 'WE', 'TH', 'FR', 'SA', 'SU'];
        return days[dayIndex];
    };

    const getWeekOfYear = (date) => {
        const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
        const pastDaysOfYear = (date - firstDayOfYear) / 86400000;
        return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
    };

    L10n.load({
        [i18n.language]: {
            'schedule': {
                'Day': i18n.t("schedule.day"),
                'Week': i18n.t("schedule.week"),
                'Month': i18n.t("schedule.month"),
                // 'agenda': i18n.t("schedule.agenda"),
            }
        }
    });

    const fetchSessionsAndSlots = useCallback(async () => {
        if (selectedLocations.length > 0) {
            dispatch(setAction(ActionTypes.SET_LOADING_SESSIONS, true));
            dispatch(setAction(ActionTypes.SET_LOADING_TIME_SLOTS, true));
            dispatch(setAction(ActionTypes.SET_ERROR, null));

            try {
                const [sessions, timeSlots] = await Promise.all([
                    calendarService.fetchSessions(accessToken, selectedLocations, selectedService),
                    calendarService.freeTimeSlots(accessToken, selectedLocations)
                ]);

                // Log the fetched data
                // console.log('Fetched sessions:', sessions.data);
                // console.log('Fetched time slots:', timeSlots.data);

                dispatch(setAction(ActionTypes.SET_SESSIONS_LIST, sessions.data));
                dispatch(setAction(ActionTypes.SET_TIME_SLOTS_LIST, timeSlots.data));

                // Log when data is stored in state
                // console.log('Stored sessions in state:', sessions.data);
                // console.log('Stored time slots in state:', timeSlots.data);
            } catch (err) {
                dispatch(setAction(ActionTypes.SET_ERROR, err));
                console.error('Error fetching data:', err);
            } finally {
                dispatch(setAction(ActionTypes.SET_LOADING_SESSIONS, false));
                dispatch(setAction(ActionTypes.SET_LOADING_TIME_SLOTS, false));
            }
        }
    }, [dispatch, selectedLocations, selectedService, accessToken]);


    const resetFiltersAndCloseAccordion = () => {
        setSelectedLocations([])
        dispatch(setAction(ActionTypes.RESET_FILTERS));
        dispatch(setAction(ActionTypes.SET_ACCORDION_INDEX, []));
    }

    // handle event render
    const handleEventRendered = (args) => {

        function hexToRgba(hex, opacity) {
            hex = hex.replace('#', '');
            const r = parseInt(hex.substring(0, 2), 16);
            const g = parseInt(hex.substring(2, 4), 16);
            const b = parseInt(hex.substring(4, 6), 16);

            return `rgba(${r}, ${g}, ${b}, ${opacity})`;
        }
        // console.log("args", args.data.BackgroundColor)
        let bg;
        if (args.data?.abv === 'fts') {
            args.element.style.backgroundColor = hexToRgba(args.data.BackgroundColor, 0.5);
            // bg = args.element.style.background;
        } else {
            args.element.style.backgroundColor = `${args.data.BackgroundColor} !important`;
            // bg = args.element.style.backgroundColor;
        }

        // console.log('Setting Background Color:', bgColor); // Log the color being set
        // args.element.style.BackgroundColor = bgColor;
    };


    const handlePopupOpen = (args) => {
        // console.log("args", args);
        if (args.type === 'Editor') args.cancel = true;
        if (args.type === 'QuickInfo') {
            const editButton = args.element.querySelector('button.e-edit');
            const closeButton = args.element.querySelector('button.e-close');
            const buttonContainer = editButton ? editButton.parentNode : null;

            if (editButton) editButton.remove();
            const deleteButton = args.element.querySelector('button.e-delete');
            if (deleteButton) deleteButton.remove();

            if (args.data.abv !== 'fts') {
                const externalLinkButton = document.createElement('a');
                externalLinkButton.href = `${window.location.origin}/#/admin/appointment-checkin/${args.data.Id}`;
                externalLinkButton.target = '_blank';
                externalLinkButton.textContent = t("calendarTab.show_details");
                externalLinkButton.style.backgroundColor = 'transparent';
                externalLinkButton.style.border = 'none';
                externalLinkButton.style.boxShadow = 'none';
                externalLinkButton.style.color = 'white';
                externalLinkButton.style.marginTop = '3px';
                externalLinkButton.className = 'e-btn';

                if (buttonContainer) {
                    if (closeButton) buttonContainer.removeChild(closeButton);
                    buttonContainer.appendChild(externalLinkButton);
                    if (closeButton) buttonContainer.appendChild(closeButton);
                }
            }
        }
    };

    const handleDateSelect = (date) => {
        setSelectedDate(date);
        dispatch(setAction(ActionTypes.SET_SELECTED_DATE, date));
    };

    // const handleLocationChange = (id) => {
    //     setSelectedLocations((prevLocations) =>
    //         prevLocations.includes(id)
    //             ? prevLocations.filter((locationId) => locationId !== id) // Deselect if already selected
    //             : [...prevLocations, id] // Select new location
    //     );
    // };

    const handleLocationChange = (id, isChecked) => {
        if (!isChecked) {
            dispatch(setAction(ActionTypes.SET_SELECTED_LOCATIONS, [...selectedLocations, id]));
        } else {
            dispatch(setAction(ActionTypes.SET_SELECTED_LOCATIONS, selectedLocations.filter(locId => locId !== id)));
        }
        dispatch(setAction(ActionTypes.SET_SELECTED_LOCATIONS, [id]));
        setSelectedLocations((prevLocations) =>
            prevLocations.includes(id)
                ? prevLocations.filter((locationId) => locationId !== id) // Deselect if already selected
                : [...prevLocations, id] // Select new location
        );
    };

    const [activeFilter, setActiveFilter] = useState("All");

    const filters = ["All", "Availability", "Sessions", "Info", "Mediation"];

    const translationMap = {
        All: "filters_all",
        Availability: "filters_availability",
        Sessions: "filters_sessions",
        Info: "filters_info",
        Mediation: "filters_mediation",
    };
    // Assuming association is coming from your props or state
    const filteredTags = association.slug === 'kiido'
        ? filters.filter(filter => filter !== "Info" && filter !== "Mediation")
        : filters;

    const handleViewChange = (args) => {
        // setToastShown(false);
        if (args.currentDate) {
            dispatch(setAction(ActionTypes.SET_SELECTED_DATE, args.currentDate));
        }
        if (args.currentView) {
            dispatch(setAction(ActionTypes.SET_CURRENT_VIEW, args.currentView));
        }
    };

    const onCellClick = (args) => {
        args.cancel = true;
    };



    const mapSessionsToCalendarEvents = (sessions) => {
        console.log("sessions", sessions);
        return sessions.map(session => {
            const utcStartDate = new Date(session.calendar_block.start_date);
            const utcEndDate = new Date(session.calendar_block.end_date);

            return {
                Id: session.id,
                Subject: session?.calendar_block.title,
                Status: session?.status,
                StartTime: utcStartDate,
                EndTime: utcEndDate,
                IsAllDay: false,
                Location: session?.location?.name || '',
                Category: session?.type,
                OriginalRdv: session?.original_rdv,
                BackgroundColor: session?.calendar_block?.color,
                // StartTimezone: 'UTC',
                // EndTimezone: 'UTC',
                rankId: 0
            };
        });
    };

    const mapTimeSlotsToRecurringEvents = (timeSlots) => {
        console.log("timeSlots", timeSlots)
        return timeSlots?.map(slot => {
            const startDate = new Date();
            console.log("first startDate", startDate)
            const utctolocalconvertStart = Utils.convertUTCToLocalTimeZone(slot.start_time);
            startDate.setHours(parseInt(utctolocalconvertStart.split(":")[0]), parseInt(utctolocalconvertStart.split(":")[1]));

            const endDate = new Date();
            const utctolocalconvertEnd = Utils.convertUTCToLocalTimeZone(slot.end_time);
            endDate.setHours(parseInt(utctolocalconvertEnd.split(":")[0]), parseInt(utctolocalconvertEnd.split(":")[1]));

            // Get the current week of the year
            const weekOfYear = getWeekOfYear(startDate);

            // Map the days to their abbreviations (MO = Monday, TU = Tuesday, etc.)
            const recurrenceDays = slot.applicable_days.map(day => getDayAbbreviation(day)).join(",");
            // Calculate the number of days remaining in the current week
            const currentDayOfWeek = startDate.getDay(); // Sunday = 0, Monday = 1, ..., Saturday = 6
            const daysRemainingInWeek = 7 - currentDayOfWeek;
            // Create the initial recurrence rule with the weekly frequency
            let recurrenceRule = `FREQ=WEEKLY;BYDAY=${recurrenceDays}`;
            console.log("weekOfYear", weekOfYear, "currentDayOfWeek", currentDayOfWeek)
            // Adjust for odd or even weeks
            if (slot.applicable_week === 'All') {
                // // For odd weeks
                // if (weekOfYear % 2 === 0) {
                //     console.log("yash******************************", weekOfYear % 2 === 0)
                    
                //     startDate.setDate(startDate.getDate() + (7 - startDate.getDay())); // Align to the next applicable day (Monday)
                //     endDate.setDate(endDate.getDate() + (7 - endDate.getDay()));
                // }
                // console.log("ODD startDate", startDate)
                recurrenceRule += `;INTERVAL=1`; // Interval 2 for odd weeks (every 2 weeks)
            } else {
                // Weekly recurrence for both odd and even weeks (default)
                recurrenceRule += `;INTERVAL=2`; // Interval 1 for weekly
            }

            return {
                Id: slot?.id,
                Subject: t("calendarTab.availability"),
                abv: 'fts',
                StartTime: startDate,
                EndTime: endDate,
                IsAllDay: false,
                Location: slot?.location?.name,
                BackgroundColor: slot?.location?.color,
                RecurrenceRule: recurrenceRule,
                rankId: 1
            };
        });
    };


    const mergeEventsWithPriority = (sessionEvents, timeSlotEvents) => {
        // console.log("sessionEvents", sessionEvents);
        const mergedEvents = [...sessionEvents];

        timeSlotEvents?.forEach(timeSlotEvent => {
            const hasOverlap = sessionEvents.some(sessionEvent => (
                timeSlotEvent.StartTime < sessionEvent.EndTime &&
                timeSlotEvent.EndTime > sessionEvent.StartTime
            ));

            if (!hasOverlap) {
                mergedEvents.push(timeSlotEvent);
            } else {
                timeSlotEvent.BackgroundColor = '#d3d3d3';
                timeSlotEvent.Subject = `Unavailable: ${timeSlotEvent.Subject}`;
            }
        });

        return mergedEvents;
    };

    const calendarBlock = useMemo(() => {
        const sessionEvents = mapSessionsToCalendarEvents(sessionsList);
        const timeSlotEvents = mapTimeSlotsToRecurringEvents(timeSlotsList);
        // console.log("sessionEvents timeSlotEvents", sessionEvents, timeSlotEvents)
        return mergeEventsWithPriority(sessionEvents, timeSlotEvents);
    }, [sessionsList, timeSlotsList]);

    const filteredCalendarBlock = useMemo(() => {
        // console.log("calendarBlock ************ ", calendarBlock)
        let events = calendarBlock;
        // console.log("events", events)
        switch (sessionTypeFilter) {
            case "Availability":
                events = events.filter(event => event.abv === 'fts');
                break;
            case "Sessions":
                events = events.filter(event => event.abv !== 'fts');
                break;
            case "Info":
                events = events.filter(event => event.Category === 'Info');
                break;
            case "Mediation":
                events = events.filter(event => event.Category === 'Mediation');
                if (mediationStatusFilter) {
                    events = events.filter(event => event.Status === mediationStatusFilter);
                }
                break;
            default:
                break;
        }

        return events;
    }, [calendarBlock]);

    const handleChangeStateType = (filter) => {
        dispatch(setAction(ActionTypes.SET_SESSION_TYPE_FILTER, filter));
        setActiveFilter(filter);
        console.log("Selected filter:", filter);
    };

    return (
        <div style={{ display: 'flex', height: '100vh' }}>
            <Box flex="1">
                <MonthCalendar onDateSelect={handleDateSelect} />
                <VStack align="stretch">
                    <Accordion defaultIndex={[0]}>
                        <AccordionItem>
                            <AccordionButton>
                                <Box flex="1" textAlign="left">
                                    {t("admin__header__locations")}
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                            <AccordionPanel>
                                <VStack align="start" maxH="40vh" overflowY="scroll">
                                    {locationsList
                                        .filter((location) =>
                                            professional_profile.locations.some((profileLocation) => profileLocation.id === location.id)
                                        )
                                        .map((filteredLocation) => (
                                            <Checkbox
                                                key={filteredLocation.id}
                                                isChecked={selectedLocations.includes(filteredLocation.id)}
                                                onChange={() => handleLocationChange(filteredLocation.id, selectedLocations.includes(filteredLocation.id))}
                                                sx={{
                                                    '.chakra-checkbox__control[data-checked]': {
                                                        bg: "#FF6666",          // Background color of the checkbox when checked
                                                        borderColor: "#FF6666",  // Border color of the checkbox when checked
                                                    },
                                                }}
                                            >
                                                {filteredLocation.name}
                                            </Checkbox>
                                        ))}
                                </VStack>
                            </AccordionPanel>
                        </AccordionItem>
                    </Accordion>

                    <Box width="100%" borderBottom="1px solid" borderBottomColor="gray.300">
                        <HStack spacing={4} p={4}>
                            {filteredTags.map((filter) => {
                                const translationKey = translationMap[filter];
                                return (
                                    <Tag
                                        key={filter}
                                        cursor="pointer"
                                        backgroundColor={activeFilter === filter ? "#FF6666" : "gray.100"}
                                        color={activeFilter === filter ? "white" : "black"}
                                        onClick={() => handleChangeStateType(filter)} // Set active filter on click
                                    >
                                        <TagLabel>{t(`calendarTab.${translationKey}`)}</TagLabel>
                                    </Tag>
                                );
                            })}
                        </HStack>
                    </Box>
                </VStack>
                <Box width="100%" display="flex" justifyContent="flex-end" mt={2} p={2}>
                    <Button
                        colorScheme="red"
                        isDisabled={
                            selectedLocations.length === 0
                        }
                        onClick={fetchSessionsAndSlots}
                    >
                        {t("calendarTab.show_planning")}
                    </Button>
                    <IconButton
                        icon={<CloseIcon />}
                        onClick={resetFiltersAndCloseAccordion}
                        aria-label={t("calendarTab.resetFilters")}
                        bg="none"
                        mx={2}
                    />
                </Box>
            </Box>

            <div style={{ flex: '3' }}>
                {isLoadingSessions || isLoadingTimeSlots ? (
                    <Flex alignItems="center" justifyContent="center" alignSelf="center" mt={12}>
                        <Spinner size="xl" color="blue.500" />
                    </Flex>
                ) : error ? (
                    <Flex alignItems="center" justifyContent="center" alignSelf="center" mt={12}>
                        <Text color="red.500">{t("calendarTab.loadFailed")}</Text>
                    </Flex>
                ) : calendarBlock.length > 0 ? (
                    <ScheduleComponent
                        width="100%"
                        height="100%"
                        eventSettings={{
                            dataSource: filteredCalendarBlock
                        }}
                        selectedDate={selectedDate}
                        currentView={currentView}
                        eventRendered={handleEventRendered}
                        popupOpen={handlePopupOpen}
                        locale={currentLocale}
                        dateFormat="yyyy/MM/dd"
                        timeFormat="HH:mm"
                        navigating={handleViewChange}
                        cellClick={onCellClick}
                        firstDayOfWeek={1}
                    >
                        <ViewsDirective>
                            <ViewDirective option="Day" />
                            <ViewDirective option="Week" />
                            <ViewDirective option="Month" />
                            <ViewDirective option="Agenda" />
                        </ViewsDirective>
                        <Inject services={[Day, Week, Month, Agenda]} />
                    </ScheduleComponent>
                ) : (
                    <Flex alignItems="center" justifyContent="center" alignSelf="center">
                        <ScheduleComponent
                            width="100%"
                            height="80vh"
                            selectedDate={new Date()}
                            currentView={currentView}
                            eventRendered={handleEventRendered}
                            popupOpen={handlePopupOpen}
                            eventDoubleClick={(args) => args.cancel = true}
                            locale={currentLocale}
                            dateFormat="yyyy/MM/dd"
                            timeFormat="HH:mm"
                            navigating={handleViewChange}
                            cellClick={onCellClick}
                            firstDayOfWeek={1}
                        >
                            <ViewsDirective>
                                <ViewDirective option="Day" />
                                <ViewDirective option="Week" />
                                <ViewDirective option="Month" />
                                <ViewDirective option="Agenda" />
                            </ViewsDirective>
                            <Inject services={[Day, Week, Month, Agenda]} />
                        </ScheduleComponent>
                    </Flex>
                )}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    accessToken: selectAccessToken(state),
    professional_profile: selectProfessionalProfile(state),
    association: selectAssociation(state)
});

export default connect(mapStateToProps)(CalendarMain);
